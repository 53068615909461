form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        user-select: none;

        &.form-control {
            &:not([size]):not([multiple]) {
                height: 57px;
            }
        }
    }

    .help-block {
        margin: 8px 0 0;
    }
}

.subscribe {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -900px;
    bottom: 20px;
    min-width: 390px;

    @media (min-height: 1500px) {
        bottom: auto;
        top: 1330px;
    }

    @media (max-width: 1850px) {
        margin-left: 0;
        left: 20px;
    }

    @media (max-width: 1100px) {
        bottom: auto;
        top: 970px;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 530px;
    }

    @media (max-width: 560px) {
        max-width: 380px;
    }

    @media (max-width: 400px) {
        top: 800px;
        max-width: 100%;
        width: 100%;
        padding: 0 15px;
    }

    > .is-h2 {
        color: $color-white;
        display: inline-block;
        padding: 13px 28px;
        background: url('/assets/img/basta/subscribe-title.svg') center center no-repeat;
        background-size: contain;
        text-align: center;
        margin-bottom: 0;
        font-size: 50px;
        line-height: 53px;

        @media (max-width: 1400px) {
            font-size: 30px;
            line-height: 23px;
        }

        @media (max-width: 560px) {
            font-size: 20px;
            line-height: 20px;
        }

        @media (max-width: 400px) {
            font-size: 19px;
            line-height: 20px;
        }
    }
}

// scss-lint:disable ImportantRule, IdSelector, SelectorFormat
#subscribe-form {
    ._form {
        margin: 0 auto !important;
        padding: 0 !important;
        font-family: $text-font !important;
        width: 98%;
        max-width: unset !important;
        min-width: unset !important;
        background: transparent !important;

        &-content {
            font-family: $text-font !important;
        }

        &-label,
        ._html-code {
            display: none !important;
        }

        input {
            border: 0 !important;
            border-radius: 0 !important;
            padding: 10px 60px !important;
            font-size: 22px !important;
            line-height: 22px !important;

            &::placeholder {
                color: $color-black-light;
            }

            @media (max-width: 1400px) {
                padding: 10px 30px !important;
            }

            @media (max-width: 560px) {
                margin-top: -12px;
                padding: 6px 20px !important;
                font-size: 18px !important;
                line-height: 18px !important;
            }

            @media (max-width: 400px) {
                margin-top: 0;
            }
        }

        button[type="submit"] {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background: none !important;
            font-size: 0 !important;

            @media (max-width: 667px) {
                bottom: 20px;
            }

            @media (max-width: 560px) {
                bottom: 15px;
                right: 0;
            }

            &::after {
                content: '';
                display: block;
                background: url('/assets/img/basta/submit.svg') center center no-repeat;
                background-size: cover;
                width: 40px;
                height: 30px;

                @media (max-width: 560px) {
                    width: 30px;
                    height: 20px;
                }
            }
        }
    }
}
// scss-lint:enable ImportantRule, IdSelector, SelectorFormat

::-webkit-input-placeholder {
    color: $color-black;
}

::-moz-placeholder {
    color: $color-black;
}

:-ms-input-placeholder {
    color: $color-black;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}
