.artwork {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: 0 auto;
    width: 100%;
    min-width: 900px;
    max-width: 1900px;
    max-height: 100vh;

    &__item {
        position: absolute;
        background-size: cover;
        background-position: 0 0;
        background-repeat: no-repeat;

        &--fist {
            background-image: url('/assets/img/basta/fist.svg');
            width: 15vw;
            height: 15vw;
            max-width: 270px;
            max-height: 270px;
            top: 240px;
            right: 50px;
        }

        &--pack {
            background-image: url('/assets/img/basta/pack.png');
            width: 47vw;
            height: 58vw;
            max-width: 800px;
            max-height: 1000px;
            top: 500px;
            right: 100px;

            @media (min-height: 1500px) {
                top: auto;
                bottom: 0;
            }
        }

        &--tomato {
            background-image: url('/assets/img/basta/tomato.png');
            width: 57vw;
            height: 72vw;
            max-width: 970px;
            max-height: 1230px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            left: -220px;
        }

        &--green-stroke {
            background-image: url('/assets/img/basta/green-stroke.svg');
            width: 26vw;
            height: 72vw;
            max-width: 440px;
            max-height: 1230px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            left: -160px;
        }

        &--black-dots {
            background-image: url('/assets/img/basta/black-dots.svg');
            width: 52vw;
            height: 31vw;
            max-width: 880px;
            max-height: 530px;
            bottom: 0;
            right: 40px;
        }

        &--yellow-chips {
            background-image: url('/assets/img/basta/yellow-chips.svg');
            width: 8vw;
            height: 13.5vw;
            max-width: 140px;
            max-height: 230px;
            bottom: 200px;
            right: 30px;
        }

        &--yellow-stroke {
            background-image: url('/assets/img/basta/yellow-stroke.svg');
            width: 31vw;
            height: 31vw;
            max-width: 530px;
            max-height: 530px;
            bottom: -230px;
            left: 50%;
            margin-left: -430px;
        }
    }

    @media (max-width: 1650px) {
        &__item {
            &--tomato {
                left: -100px;
            }
        }
    }

    @media (max-width: 1100px) {
        position: absolute;
        left: auto;
        max-height: unset;
        min-height: 1170px;
        overflow-x: hidden;
        overflow-y: visible;

        &__item {
            &--fist {
                width: 160px;
                height: 160px;
                top: 240px;
                right: 10px;
            }

            &--pack {
                background-size: 100%;
                width: 510px;
                height: 270px;
                top: 900px;
                right: 10px;
            }

            &--tomato {
                width: 620px;
                height: 780px;
                top: -200px;
                bottom: auto;
                left: 50%;
                margin: 0 0 0 -50vw;
            }

            &--green-stroke {
                width: 280px;
                height: 780px;
                top: -300px;
                bottom: auto;
                left: 50%;
                margin: 0 0 0 -35vw;
            }

            &--black-dots {
                width: 560px;
                height: 330px;
                right: -40px;
                top: 740px;
            }

            &--yellow-chips {
                width: 160px;
                height: 260px;
                bottom: auto;
                top: -50px;
                right: -10px;
            }

            &--yellow-stroke {
                width: 340px;
                height: 340px;
                bottom: -230px;
                left: 50%;
                margin-left: -430px;
            }
        }
    }

    @media (max-width: 560px) {
        &__item {
            &--black-dots {
                top: 900px;
            }
        }
    }

    @media (max-width: 400px) {
        min-width: 390px;
        left: 0;

        &__item {
            &--fist {
                top: 90px;
            }

            &--tomato {
                margin-left: -390px;
                top: -290px;
            }

            &--green-stroke {
                margin-left: -390px;
            }

            &--yellow-chips {
                top: -100px;
            }
        }
    }
}
