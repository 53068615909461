// Bootstrap settings
$container-max-widths: (
        'sm': 540px,
        'md': 720px,
        'lg': 960px,
        'xl': 1170px
) !default;

// Overall
$default-margin: 20px;
$default-block-margin: 60px;
$text-font: 'True North', sans-serif;
$heading-font: 'True North', sans-serif;

$text-size: 20px;
$line-height: 30px;
$text-color: #000;

$color-white: #fff;
$color-pink: #ed1164;
$color-black: #000;
$color-black-off: #333;
$color-black-light: #666;
$color-gray: #b8b9ba;
$color-gray-1: #f5f7f8;
$color-gray-2: #eaeaea;
$color-gray-3: #f7f7f7;
$color-red: #db001b;
$color-yellow: #ffea50;
$color-blue: #10cfc9;
$color-green: #37ad72;
$color-blue-1: #339;
$color-error: #f37c7b;

$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
