.block {
    &--article {
        $self: &;
    }
}

.article {
    position: absolute;
    max-width: 880px;
    right: 50%;
    margin-right: -570px;
    padding: 80px 15px 0;

    &__title {
        h1 {
            text-align: center;
            font-size: 60px;
            line-height: 60px;
        }
    }

    &__content {
        text-align: center;
        font-size: 30px;
        line-height: 40px;
    }

    @media (max-width: 1650px) {
        max-width: 760px;
        margin-right: -530px;

        &__title {
            h1 {
                font-size: 50px;
                line-height: 50px;
            }
        }

        &__content {
            font-size: 25px;
            line-height: 33px;
        }
    }

    @media (max-width: 1400px) {
        max-width: 510px;
        margin-right: -350px;
    }

    @media (max-width: 1100px) {
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-top: 440px;
    }

    @media (max-width: 560px) {
        &__title {
            h1 {
                font-size: 50px;
                line-height: 50px;
            }
        }

        &__content {
            font-size: 25px;
            line-height: 33px;
        }
    }

    @media (max-width: 480px) {
        &__title {
            h1 {
                font-size: 50px;
                line-height: 50px;
                margin-bottom: 50px;
            }
        }

        &__content {
            font-size: 20px;
            line-height: 30px;
        }
    }

    @media (max-width: 400px) {
        padding-top: 270px;
    }

}
